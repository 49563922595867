import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import InquiryForms from "../molecules/Contactus/InquiryForms";
import InsightsHeader from "../molecules/InsightsPage/insightsHeader";
import InquiryMap from "../molecules/Contactus/InquiryMap.js";
import ContactForm from "../molecules/ContactForm/contactForm2.js";

const aboutus = () => {
  return (
    <Layout isNavWhite={true} hideMap={true} hideFAQ>
      <SEO
        canonicalLink={"https://www.goken-global.com/contactus/"}
        title={"Engineering Services & Staffing Solutions Contact Us | Goken"}
        description={
          "Get in touch with Goken Global for expert engineering solutions and innovative technology services."
        }
        facebookDescription={
          "Contact Goken for Engineering Services & Staffing Solutions in USA, India, Japan. We are here to serve you in innovation, product design, product engineering services and more."
        }
        facebookTitle={
          "Goken | Engineering Services & Staffing Solutions - Contact Us"
        }
        pageName={"Contact Us"}
      ></SEO>

      {/* <InsightsHeader
        heading="CONTACT US"
        bgImageClass="bg-image-insights-page"
        paraOne="Reach out to us if you are looking to build a high performance team"
      ></InsightsHeader> */}
      {/* <InquiryForms></InquiryForms> */}
      <ContactForm />
      {/* <InquiryMap></InquiryMap> */}
    </Layout>
  );
};

export default aboutus;
